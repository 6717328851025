<template>
  <div class="content">
    <div class="container-fluid">
      <div class="container">
        <div class="row">
          <div class=" card col-md-3 col-12 mr-3 h-75">
            <div class="card-header card-header-success">
              <h4 class="card-title">Descripción de la vista</h4>
            </div>
            <div class="card-body p-2">
              <ul class="list-inside pt-2 pl-3 pr-3 space-y-2">
                <li>
                  Descargue sus tarjetas para devoluciones. Cada punto de venta
                  configurado permite la descarga de una tarjeta personalizada
                  para gestionar devoluciones de las transacciones realizadas a
                  través de nuestra app de cobros.
                </li>
                <li>
                  Esto facilita a los usuarios procesar reembolsos de manera
                  rápida y segura, asegurando la trazabilidad de cada operación
                  de devolución.
                </li>
              </ul>
            </div>
          </div>
          <div class="card col-md-8 col-12">
            <div class="card-header card-header-success">
              <h4 class="card-title">Puntos de Venta</h4>
            </div>

            <div class="card-body p-0" v-if="!sinDatos">
              <v-row v-if="cargando">
                <v-col md="9">
                  <v-skeleton-loader
                    v-bind="attrs"
                    type="list-item-three-line"
                    v-if="cargando"
                  ></v-skeleton-loader>
                </v-col>
                <v-col md="3">
                  <v-skeleton-loader
                    v-bind="attrs"
                    type="actions"
                    v-if="cargando"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
              <ul class="list-group" v-else>
                <li
                  class="list-group-item flex justify-content-between align-items-center"
                  v-for="item in itemsPuntos"
                  :key="item.id"
                >
                  {{ item.nombre }}
                  <div class="btn-group gap-x-4">
                    <!-- Para Nuevo Código -->
                    <!-- <v-tooltip top color="success">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          large
                          class="ma-3 bg-success text-white"
                          v-bind="attrs"
                          v-on="on"
                          :loading="loadNew === item.id"
                          @click="nuevoCodDevolucion(item.id)"
                        >
                          <v-icon> mdi-key</v-icon>
                        </v-btn>
                      </template>
                      <span>Nuevo Código Devolución</span>
                    </v-tooltip> -->

                    <!-- Para Descargar Nuevo Código PDF -->
                    <v-tooltip top color="error">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          large
                          class="ma-3 bg-danger text-white"
                          v-bind="attrs"
                          v-on="on"
                          :loading="loadDescarga === item.id"
                          @click="descargaCod(item.id)"
                        >
                          <v-icon> mdi-file-pdf-box </v-icon>
                        </v-btn>
                      </template>
                      <span>Descargar Código Devolución</span>
                    </v-tooltip>
                  </div>
                </li>

                <template>
                  <v-row class="no-gutters mt-2 mx-2">
                    <v-flex xs12>
                      <span>Mostrar</span>
                      <v-menu top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            text
                            style="color:#50B565"
                            class="ml-2"
                            v-on="on"
                          >
                            {{ rowsPerPage }}
                            <v-icon>arrow_drop_down</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(number, index) in rowsPerPageArray"
                            :key="index"
                            @click="updateRowsPerPage(number)"
                          >
                            <v-list-item-title> {{ number }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-flex>
                  </v-row>
                </template>
                <!-- Paginacion -->
                <div class="text-center mt-2">
                  <v-pagination
                    circle
                    color="rgb(80,181,104)"
                    :value="page"
                    :length="total"
                    :total-visible="visible"
                    @input="onPageChange"
                  ></v-pagination>
                </div>
              </ul>
            </div>
            <div
              class="card-body flex justify-center items-center h-full"
              v-else
            >
              <div>
                <h1 class="grey darken-1">
                  No posee puntos de venta
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Componente vue-html2pdf -->
      <vue-html2pdf
        ref="pdf"
        :show-layout="false"
        :enable-download="true"
        filename="qr_devolucion.pdf"
        :pdf-quality="2"
        :manual-pagination="true"
        style="display: none;"
      >
        <!-- Contenido a convertir en PDF -->
        <div id="qr-container" slot="pdf-content" class="pdf-center-container">
          <!-- QR -->
          <div
            class="rounded-[40px] border-4 border-cyan-400"
            style="max-width:480px;"
          >
            <div
              class="rounded-t-[36px] border-b-4 border-cyan-400 no-gutters bg-[#C2D501]"
            >
              <!-- logo Wee! App -->
              <img class="w-[80px] ml-4" src="/assets/img/logo_wee_app.png" />
            </div>

            <v-row class="items-start no-gutters m-5 bg-white">
              <!-- Columna con el texto alineado -->
              <span class="mr-2 max-w-[230px] break-words">
                <ul class="text-start">
                  <li class="my-1">
                    <strong class="font-bold">Cliente:</strong>
                    {{ respuesta.nombre_fantasia }}
                  </li>
                  <li class="my-1">
                    <strong class="font-bold">CUIT:</strong>
                    {{ respuesta.cuit }}
                  </li>
                  <li class="my-1">
                    <strong class="font-bold">Cuenta:</strong>
                    {{ respuesta.cuenta }}
                  </li>
                  <li class="my-1">
                    <strong class="font-bold">Sucursal:</strong>
                    {{ respuesta.nombre }}
                  </li>
                </ul>
              </span>

              <!-- Columna con el QR -->
              <span class="ml-3">
                <qrcode-vue :value="respuesta.clave" :size="120"></qrcode-vue>
              </span>
            </v-row>
          </div>
          <!-- End QR -->
        </div>
      </vue-html2pdf>

      <!-- QR -->
      <!--
      <div
        class="rounded-[40px] border-4 border-cyan-400"
        style="max-width:480px;"
      >
        <div
          class="rounded-t-[36px] border-b-4 border-cyan-400 no-gutters bg-[#C2D501]"
        >
          
          <img class="w-[80px] ml-4 " src="/assets/img/logo_wee_app.png" />
        </div>

        <v-row class="items-start no-gutters m-5 bg-white">
          
          <span class="mr-2 max-w-[230px] break-words ">
            <ul>
              <li class="my-1">
                <strong class="font-bold">Cliente:</strong
                >{{ respuesta.nombre_fantasia }}
              </li>
              <li class="my-1">
                <strong class="font-bold">CUIT:</strong>{{ respuesta.cuit }}
              </li>
              <li class="my-1">
                <strong class="font-bold">Cuenta:</strong>{{ respuesta.cuenta }}
              </li>
              <li class="my-1">
                <strong class="font-bold">Sucursal:</strong
                >{{ respuesta.nombre }}
              </li>
            </ul>
          </span>

         
          <span class="ml-3">
            <qrcode-vue :value="respuesta.clave" :size="120"></qrcode-vue>
          </span>
        </v-row>
      </div> -->
      <!-- End QR -->
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { Cuentas } from "../../services/cuentas";
import QrcodeVue from "qrcode.vue";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "PuntosdeVenta",
  components: { QrcodeVue, VueHtml2pdf },

  data() {
    return {
      respuesta: "",
      cuenta: "",
      loadDescarga: false,
      loadNew: false,
      loading: false,
      cargando: false,
      sinDatos: false,
      error: false,
      errored: false,
      itemsPuntos: [],
      rowsPerPage: 4,
      rowsPerPageArray: [4, 8, 16],
      cuentas_id: "",

      page: 1,
      total: 0,
      visible: 4,

      attrs: "",
      puntodeventaID: "",
    };
  },

  mounted() {
    this.getDatos();
    this.buscarDatosDeCuenta();
  },

  watch: {
    getCurrentAccountId() {
      this.getDatos();
    },
  },

  methods: {
    updateRowsPerPage(number) {
      this.rowsPerPage = number;
      this.page = 1; // Reinicia la página al cambiar la cantidad de filas por página
      this.getDatos();
    },

    onPageChange(page) {
      this.page = page; // Actualiza la página actual
      this.getDatos();
    },

    buscarDatosDeCuenta() {
      this.cuenta = this.$store.state.CLIENTE_CUENTA_DATA.cuenta.find(
        (c) => c.id === this.$store.state.cuenta_ID
      );
    },

    async getDatos() {
      this.cargando = true; // Comienza a cargar
      try {
        const response = await Cuentas.getPuntosdeVenta(
          this.page,
          this.rowsPerPage,
          this.$store.state.cuenta_ID
        );

        if (!response.data.data.puntos_ventas) {
          this.itemsPuntos = [];
          this.sinDatos = true;
          this.page = 1;
          this.total = 0;
        } else {
          this.itemsPuntos = response.data.data.puntos_ventas;
          this.page = response.data.data.meta.page.currentPage;
          this.total = response.data.data.meta.page.lastPage;
        }
      } catch (error) {
        console.error("Error al consultar puntos de venta: ", error);
        this.errored = true; // Manejo del error
      } finally {
        this.cargando = false; // Finaliza la carga
      }
    },

    //Descarga de Código de Devolución
    async descargaCod(id) {
      this.loadDescarga = id;
      let response = await Cuentas.getClavePuntodeVenta({ puntoVentaId: id });

      try {
        this.$toastr.s(response.data.message);
        this.respuesta = response.data.data;
        this.generarPDF();
        console.log("Respuesta", this.respuesta);
      } catch (error) {
        this.$toastr.e(error);
      }
      this.loadDescarga = null;
    },

    // Crear nuevo código de Devolución
    async nuevoCodDevolucion(params) {
      this.$swal({
        title: "Desea crear nuevo código devolución?",
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#4CAF50",
        cancelButtonColor: "#f44336",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        this.loadNew = params;
        if (result.value) {
          try {
            let response = await Cuentas.updateClavePuntodeVenta(params);

            this.$swal.fire({
              title: response.data.message,
              // text: "Código de Devolución creado con éxito!",
              icon: "success",
              confirmButtonText: "Confirmar",
              confirmButtonColor: "#4CAF50",
            });
          } catch (error) {
            this.$swal.fire({
              title: error.message,
              // text: "Código de Devolución creado con éxito!",
              icon: "error",
              confirmButtonText: "Confirmar",
              confirmButtonColor: "#4CAF50",
            });
          }
        } else {
          this.$swal.fire({
            title: "La acción ha sido cancelada",
            // text: "La acción ha sido cancelada",
            icon: "info",
            confirmButtonText: "Confirmar",
            confirmButtonColor: "#076B84",
          });
        }
        this.loadNew = null;
      });
    },
    generarPDF() {
      // Llama al método para generar el PDF
      this.$refs.pdf.generatePdf();
    },
  },

  computed: {
    ...mapGetters([
      "isSuperAdmin",
      "CLIENTE_CUENTA_DATA",
      "getCurrentAccountId",
    ]),
  },
};
</script>

<style scoped>
.list-group-item :first-child {
  margin-right: 0rem !important;
}
.pdf-center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  flex-direction: column;
}
</style>
